import React from 'react';
import PopUpComponent from "./Nav/PopUpComponent";

const PhotoPopUp = ({
                        product_type,
                        product_id,
                        photo_file_name,
                        site_id,
                        folder_id,
                        effect_id,
                        effect_file_id_for,
                        photo_id,
                        product_combivel_id = 0, // Default value if not provided
                        background_color_5,
                        t,
                        message,
                        closePopup,
                        primary_color_4,
                        background_color_2,
                        border_radius,
                        effect_file_name
                    }) => {
    const PHOTO_URL = process.env.REACT_APP_PHOTO_URL;
    const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL;

    if (message) {
        let imageUrl = `${PHOTO_URL}${site_id}/${folder_id}/${product_combivel_id}/${effect_id}/${effect_file_id_for}/0/${photo_id}/${photo_file_name}`;
        if(product_type==='divers'){
            imageUrl = `${PRODUCT_URL}${site_id}/${photo_id}/${product_id}/${photo_file_name}`;
        }
        if(product_type==='product'){
            imageUrl = `${PRODUCT_URL}${site_id}/${photo_id}/${product_id}/${photo_file_name}`;
        }

        console.log(imageUrl);



        return (
            <PopUpComponent
                primary_color_4={primary_color_4}
                background_color_2={background_color_2}
                border_radius={border_radius}
                content={
                    <div style={{
                        backgroundImage: `url(${effect_file_name})`,
                        backgroundSize: 'cover',
                        textAlign: 'center'
                    }}>
                        <img
                            src={imageUrl}
                            className="img-sizing-1"
                            id={photo_id}
                            alt={photo_file_name}
                            style={{ border: `1px solid ${background_color_5}` }}
                        />
                    </div>
                }
                btn2={t('Go back')}
                closePopup={closePopup}
            />
        );
    }
    return null;
}

export default PhotoPopUp;
