import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import GeneralButton from '../Buttons';

const PhotoItem = ({ photo, selected, siteId, changePhoto, backgroundColors, borderRadius, t }) => {
    const handleMouseOver = () => {
        if (!iOS()) {
            changePhoto && changePhoto(
                photo.id,
                photo.folder.folder_id,
                photo.file_name,
                photo.productcategory[0].categorie_id
            );
        }
    };

    function iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    return (
        <Grid
            item
            xs={12}
            md={3}
            lg={3}
            xl={3}
            className="text-center bottom-card"
            key={photo.id}
        >
            <Link
                style={{ float: `left` }}
                to={{
                    pathname: `/shoppage/photo_id/${photo.id}`,
                    state: {
                        photo_id: photo.id,
                        folder_id: photo.folder.folder_id,
                        photo_file_name: photo.file_name,
                        category_ids: photo.productcategory[0].categorie_id,
                    },
                }}
                className="photos image-link"
                onMouseOver={handleMouseOver}
            >
                <img
                    style={{
                        border: `1px solid ${backgroundColors.color5}`,
                        borderRadius: `${borderRadius}`,
                    }}
                    alt={photo.file_name}
                    src={`${process.env.REACT_APP_PHOTO_URL}${siteId}/${photo.folder.folder_id}/0/0/0/0/${photo.id}/${photo.file_name}`}
                    className={`small-image-size image-home ${selected ? 'foto1' : 'foto2'}`}
                />
                <div
                    className="image-overlay"
                    style={{ backgroundColor: `${backgroundColors.color5}` }}
                >
                    <div className="image-text">
                        <GeneralButton className="width-fit-content align-around home-button">
                            {t("photosComponent:View your photo")}
                        </GeneralButton>
                    </div>
                </div>
            </Link>
        </Grid>
    );
};

export default PhotoItem;