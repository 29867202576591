import React, { useState, useEffect } from 'react';
import { FormGroup, FormLabel, Select, MenuItem } from '@material-ui/core';

const ProductColorSelector = (props) => {

    // get colors of product and select default of dropdown menu.
    const colors = props.product_colors !== null && props.product_colors !== '' ? props.product_colors.split(";") : null;
    let color = 'Kleur';
    if(colors !== null) {
        if(colors.length === 1){
            color = colors[0];
        } else {
            color = 'Kleur';
        }
    }

    const [product_color, setProductColor] = useState(color);
    const [show, setShow] = useState("");

    useEffect(() => {
        if (!props.product_colors || props.product_colors === null || props.product_colors === '') {
            setShow("hide");
        } else {
            setShow("show");
        }
    }, [props.product_colors]);

    const handleColorChangePhoto = (event) => {
            setProductColor(event.target.value);
            props.handleColorChangePhoto(event);
    };

    if (props.photo_file_name && props.photo_file_name.startsWith('ZW')) {
        return null;
    }

    return (
        colors !== null && (
            <FormGroup
                style={{
                    width: `98%`,
                }}
            >
                <FormLabel className={show}>
                    {props.t("shoppingcart:Image Effect")}
                </FormLabel>
                <Select
                    className={show}
                    defaultValue={product_color}
                    onChange={(event) => {
                        props.handleColorChange(event);
                        handleColorChangePhoto(event);
                    }}
                >
                    {colors.map((color) => {
                        return (
                            <MenuItem
                                key={color}
                                id={props.product_id}
                                value={color}
                                className="Select-options"
                            >
                                {color}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormGroup>
        )
    );
};

export default ProductColorSelector;
