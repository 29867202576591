import React, {useEffect, useState} from 'react';
import {Grid, Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PopUpComponent from "../../components/Nav/PopUpComponent";
import EffectComponent from "../../components/Photo/EffectComponent";

const GreenScreenComponent = ({
                                  photo_file_name,
                                  product_type,
                                  site_id,
                                  primary_color_4,
                                  background_color_2,
                                  messages,
                                  effectData,
                                  effectFileNameFor,
                                  effectFileNameBehind,
                                  user_id,
                                  photo_id,
                                  closepopup_effect,
                                  t
                              }) => {
    const [showBackgroundPopup, setShowBackgroundPopup] = useState(false);
    const [showOverlayPopup, setShowOverlayPopup] = useState(false);

    const fileNameExtension = photo_file_name?.split('.', 2)[1];
    const isPNG = fileNameExtension === "png";
    const isNotDivers = product_type !== 'divers';

    const getEffectsCount = (type) =>
        effectData ? effectData.filter(effect => effect.voor_achter === type).length : 0;
    const achterCount = getEffectsCount('achter');
    const voorCount = getEffectsCount('voor');

    // Function to clear overlay effects
    const clearBackground = () => {
        // Logic to clear overlay effects
        closepopup_effect('', '', 'voor');
        closepopup_effect('', '', 'achter');
    };


    useEffect(() => {
        if (messages.message===false) {
            setShowBackgroundPopup(false)
            setShowOverlayPopup(false)
        }
    }, [messages.message, closepopup_effect, setShowBackgroundPopup]);


    const renderButton = (type, count, text, siteIdCondition, showPopup, setShowPopupFunction) => (
        <>
            <Button
                style={{
                    color: primary_color_4,
                    backgroundColor: background_color_2,
                }}
                className="btn btn-light form-control btn-layout"
                onClick={() => setShowPopupFunction(prev => !prev)}
            >
                {t(text)}
            </Button>
            {showPopup &&  (
                <PopUpComponent
                    text={<Alert severity="info">{t(text)}</Alert>}
                    content={
                        <EffectComponent
                            user_id={user_id}
                            effectData={effectData}
                            photo_id={photo_id}
                            product_type={product_type}
                            type={type}
                            closepopup_effect={closepopup_effect}
                        />
                    }
                    btn2={t("shoppingcart:Continue Shopping")}
                    closePopup={() => setShowPopupFunction(false)}
                />
            )}
        </>
    );

    return (
        <Grid container>
            {isPNG && isNotDivers && achterCount !== 0 && (
                <Grid item lg={6} className="card-footer-item Number">
                    {renderButton('achter', achterCount, "shoppingcart:Background", '180', showBackgroundPopup, setShowBackgroundPopup)}
                </Grid>
            )}
            {isNotDivers && voorCount !== 0 && (
                <Grid item lg={6} className="card-footer-item Number">
                    {renderButton('voor', voorCount, "shoppingcart:Overlay", '124', showOverlayPopup, setShowOverlayPopup)}
                </Grid>
            )}
            <Grid item lg={6} className="card-footer-item Number">
                {isNotDivers && (achterCount !== 0 || voorCount !== 0)  && (effectFileNameFor !== '' || effectFileNameBehind !== '') &&
                    <>
                        <Button
                            style={{
                                color: primary_color_4,
                                backgroundColor: background_color_2,
                            }}
                            className="btn btn-light form-control btn-layout"
                            onClick={clearBackground}>
                            {t("shoppingcart:Clear Background")}
                        </Button>
                    </>
                }
            </Grid>
        </Grid>
    );
};

export default GreenScreenComponent;
